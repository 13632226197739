<template>
  
    <banner :banner="banner" :caption="caption" :path="bannerPath"></banner>

    <section class="content-home border-bottom-red">
        <div class="container-fluid">
            <div class="row">
                <div class="col-lg-5 col-sm-12 layanan-biru">
                    <div class="row mt-3">
                        <div class="col-2">
                            <div class="content-block">
                                <img src="../../assets/images/layanan_biru.png" alt="icon" class="icon-white img-left">
                            </div>
                        </div>

                        <div class="col-10">
                            <h6 class="text-white ml-2">LAYANAN</h6>
                            <h6 class="text-white ml-2">BAHTERA ADHIGUNA</h6>
                        </div>
                    </div>

                    <template v-for="data in dataLayanan" :key="data.keyid">
                        <div class="border-bottom-white mt-3">
                            <a>
                                <router-link :to="'/layanan/selengkapnya/' + data.kode_layanan">
                                    <h6 class="text-white"> {{ data.nama_layanan }} </h6>
                                </router-link>
                            </a>

                            <span class="text-white">
                                <p v-html="data.isi_layanan.substr(3, 200)"></p>
                            </span>
                        </div>
                    </template>

                    <div class="more-layanan">
                        <router-link to="/layanan_lainnya">
                            <a><b>LAYANAN</b> selengkapnya <i class="zmdi zmdi-caret-right-circle"></i></a>
                        </router-link>
                    </div>
                </div>

                <div class="col-lg-7 col-sm-12">
                    <div class="row mt-3">
                        <div class="col-2">
                            <div class="content-block">
                                <img class="icon-red  img-left" src="../../assets/images/layanan_transportasi.png" alt="icon">
                            </div>
                        </div>

                        <div class="col-10">
                            <h6 class="text-primary"><b>LAYANAN</b></h6>
                            <h6 class="text-danger">TRANSPORTASI BATUBARA & MANAJEMEN PENGERUKAN</h6>
                            <p class="mb-5">Kami saat ini memiliki, mengelola, dan mengoperasikan beberapa kapal dan sea train untuk mengangkut komoditi di Indonesia.</p>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-lg-6 col-sm-12 mb-3">
                            <h6 class="text-title">ARMADA KAPAL</h6>
                            <h6 class="text-title mb-5">TRANSPORTASI BATU BARA</h6>
                            <template v-for="data in dataTransportasi" :key="data.keyid">
                                <img :src="armadaPath + data.gambar_armada" class="img-transport" alt="ikon">
                                <a>
                                    <router-link :to="'/armada/detail/' + data.keyid">
                                        <h6 class="text-title">
                                            {{ data.nama_armada }}
                                        </h6>
                                    </router-link>
                                </a>
                                <div class="post-exerpts">
                                    <p v-html="data.deskripsi"></p>
                                </div>
                            </template>
                        </div>

                        <div class="col-lg-6 col-sm-12">
                            <h6 class="text-title-danger">ARMADA KAPAL</h6>
                            <h6 class="text-title-danger mb-5">MANAJEMEN PENGERUKAN</h6>
                            <template v-for="data in dataPengerukan" :key="data.keyid">
                                <img :src="armadaPath + data.gambar_armada" class="img-transport" alt="ikon">
                                <a>
                                    <router-link :to="'/armada/detail/' + data.keyid">
                                        <h6 class="text-title-danger">
                                            {{ data.nama_armada }}
                                        </h6>
                                    </router-link>
                                </a>
                                <div class="post-exerpts">
                                    <p v-html="data.deskripsi"></p>
                                </div>
                            </template>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

</template>

<script>

import Banner from '../banner/banner.vue'
import axios from 'axios'

export default {
    name: 'pageLayanan',
    components: {
        Banner,
    },
    data () {
        return {
            // bannerPath: 'http://localhost:8080/assets/banner/',
            bannerPath: 'https://api.plnbag.co.id/assets/banner/',
            banner: '',
            caption: '',
            dataLayanan: [],
            dataTransportasi: [],
            // armadaPath: 'http://localhost:8080/assets/armada/',
            armadaPath: 'https://api.bahteradhiguna.co.id/assets/armada/',
            dataPengerukan: []
        }
    },
    mounted () {
        this.loadBanner()
        this.loadLayanan()
        this.loadTransportasi()
        this.loadPengerukan()
    },
    methods: {
        loadBanner () {
            axios.get('layanan_banner')
                .then((res) => {
                    this.banner = res.data.data.banner
                    this.caption = res.data.data.caption_indo
                })
        },

        loadLayanan () {
            axios.get('layanan_layanan')
                .then((res) => {
                    this.dataLayanan = res.data.data
                })
        },

        loadTransportasi () {
            axios.get('layanan_transportasi')
                .then((res) => {
                    this.dataTransportasi = res.data.data
                })
        },

        loadPengerukan () {
            axios.get('layanan_pengerukan')
                .then((res) => {
                    this.dataPengerukan = res.data.data
                })
        }
    }
}
</script>

<style scoped>
    .layanan-biru {
        background:#0158a7;
    }

    .img-left {
        width: 85px;
        height: auto;
    }

    @media screen and (max-width: 720px) {
        .img-left {
            width: 45px;
            height: auto;
        }
    }

    .icon-white {
        background-color: #ffffff;
        border-radius: 5px;
        padding: 10px;
    }

    .border-bottom-white {
        border-bottom: 3px solid #ffffff;
    }

    /* h6 {
        font-size: 20px;
    } */

    p {
        font-size: 12px;
    }

    .more-layanan {
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }

    .more-layanan a {
        text-decoration: none;
        color: #ffffff;
        margin-top: 10px;
        margin-bottom: 10px;
    }

    .border-bottom-red {
        border-bottom: 5px solid #ea2e23;
    }

    .icon-red {
        background-color: #ea2e23;
        border-radius: 5px;
        padding: 10px;
        float: right;
    }

    @media (max-width: 767px) {
        .icon-red {
            float: none;
        }
    }

    .img-transport {
        height: auto;
        width: 130px;
    }

    a {
        text-decoration: none;
    }

    .post-exerpts {
        line-height: 9px;
    }

    .text-title {
        color: #0158a7;
    }

    .text-title-danger {
        color: #ea2e23;
    }
    
</style>